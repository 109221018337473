.overall-stats {
  .stat-card {
    .ant-card-body {
      padding: 10px 24px;
      p {
        margin: 0;
      }
    }
  }
}
