.npsModal {
  .ant-modal-header {
    padding: 34px 24px 0 24px;
    border-bottom: 0;
    h1 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 6px;
    }
    p {
      color: #707070;
      font-size: 12px;
      margin: 0;
    }
  }
  .ant-select-selection-selected-value {
    width: 100%;
    svg {
      display: block;
      margin-left: auto;
      margin-right: 15px;
    }
  }
  .form-item-group {
    margin-bottom: 15px;
    label {
      display: block;
      text-align: left;
    }
  }
  .form-item-group.has-error {
    label {
      color: red;
    }
    .error-label {
      color: red;
    }
    .ant-input {
      border-color: red;
    }
  }
  .ant-form-item-label {
    line-height: 27px;
    font-size: 14px;
    color: #242425;
    // margin-bottom: 8px;
  }
}
