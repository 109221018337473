.customer-details-modal {
  .ant-modal-header {
    padding: 34px 24px 0 24px;
    border-bottom: 0;
    h1 {
      font-size: 20px;
      font-weight: 500;
      color: black;
      margin-bottom: 6px;
    }
    p {
      font-size: 12px;
      color: #707070;
    }
  }
  .field {
    label {
      color: #707070;
      line-height: 22px;
      font-size: 12px;
    }
    p {
      font-size: 12px;
      color: black;
      background: #eeeeee;
      padding: 10px 8px;
    }
  }
  .past-orders {
    max-height: 169px;
    overflow: auto;
    padding: 12px 8px;
    background: #eeeeee;
    .order {
      padding-bottom: 16px;
      .order-date {
        background: #e5e5e5;
        font-size: 8px;
        color: #797979;
        padding: 3px 8px;
      }
      .order-items {
        .order-item {
          font-size: 12px;
          color: black;
          margin-bottom: 8px;
        }
      }
    }
  }
}
