@import "~antd/dist/antd.css";

html,
body {
  min-width: 1280px;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root,
.content-body {
  height: 100%;
}
html {
  font-size: 12px;
  --themecolor: transparent;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(12px + 4 * ((100vw - 320px) / 961));
  }
}
@media screen and (min-width: 1281px) {
  html {
    font-size: 16px;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none !important;
}

.ant-card {
  border-radius: 4px;
  box-shadow: 0rem 0.21rem 0.42rem 0 rgba(0, 0, 0, 0.16);
}
.ant-tabs-tab {
  font-size: 1.14rem;
}
.ant-tabs-tab-active,
.ant-tabs-tab:active,
.ant-tabs-tab:hover {
  color: #23202c !important;
}
.ant-tabs-ink-bar {
  background: #23202c;
}

.ant-pagination-item-active,
.ant-pagination-item:hover,
.ant-pagination-item:active {
  background: #ff5858 !important;
  border-color: #ff5858 !important;
  outline: none !important;
}
.ant-pagination-item a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-pagination-item-active a,
.ant-pagination-item:hover a,
.ant-pagination-item:active a {
  color: #ffffff !important;
}

.delete-button,
.delete-button:hover,
.delete-button:active {
  background: #ffffff !important;
  color: #f84b3d !important;
  border-color: #f84b3d !important;
  outline: none !important;
  font-size: 0.86rem;
}

.ant-skeleton,
.ant-alert-error {
  width: 90%;
  margin: auto;
}

.ant-modal-mask {
  background: rgba(2, 4, 41, 0.9);
}
