.top-products {
  margin: 3rem 0 2rem 0;
  table {
    thead {
      th {
        background: #16273b !important;
        color: white;
      }
    }
    tbody {
      background: white;
    }
  }
  .product-details {
    display: flex;
    img {
      width: 120px;
      height: 120px;
      border: 1px solid black;
    }
    .product-info {
      margin-left: 12px;
      h4 {
        color: #23202c;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 8px;
      }
      p {
        color: #000000a6;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 8px;
      }
    }
  }
}
