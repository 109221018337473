.product-container {
  .category-tag {
    background: #e3fcff;
    padding: 8px 12px;
  }
  .product-list {
    .product-details {
      display: flex;
      img {
        width: 120px;
        border: 1px solid black;
      }
      .product-info {
        margin-left: 12px;
        h4 {
          color: #23202c;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          margin-bottom: 8px;
        }
        p {
          color: #000000a6;
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          margin-bottom: 8px;
        }
      }
    }
    .product-description {
      background: #f2f2f2;
      padding: 10px;
      p {
        margin-bottom: 0px;
        color: #4b4b4b;
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
      }
    }
    .product-cost {
      margin-bottom: 0px;
      color: #23202c;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }
    .product-actions {
      display: flex;
      align-items: center;
      color: black;
      font-weight: 500;
      justify-content: center;
      .ant-switch-checked {
        background-color: #36c626;
      }
      .anticon {
        font-size: 36px;
        color: #5c5c5c;
        margin-left: 5px;
      }
      .ant-switch {
        margin-right: 5px;
      }
      .anticon:hover {
        color: #0068ff;
      }
    }

    table {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      thead {
        tr {
          th {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.3px;
            background: #16273b !important;
            border-radius: 0 !important;
            color: white !important;
          }
        }
        th:nth-child(3),
        th:nth-child(4) {
          text-align: center;
        }
      }
      tbody {
        background: white;
        tr {
          td {
            font-size: 13px;
            border-bottom-color: #f2f2f2 !important;
          }
          td:nth-child(3),
          td:nth-child(4) {
            text-align: center;
          }
        }
      }
    }
  }
  .ant-table-pagination.ant-pagination {
    margin: 36px 0px;
    float: unset;
    text-align: center;
  }
  .ant-pagination-item-active,
  .ant-pagination-item:hover,
  .ant-pagination-item:active {
    background: #1655c0 !important;
    border-color: #1655c0 !important;
    outline: none !important;
  }
  .ant-pagination-item {
    background: white;
    border-color: #707070;
    outline: none !important;
  }
  .ant-pagination-item a {
    color: #000000;
  }
  .ant-pagination-item-active a,
  .ant-pagination-item:hover a,
  .ant-pagination-item:active a {
    color: #ffffff !important;
  }
  .campaign-empty-state {
    background-image: url("https://d2t1ofb0zlav1n.cloudfront.net/global/images/campaign-state.svg");
    width: 1090px;
    background-repeat: no-repeat;
    margin: auto;
    height: 391px;
    padding: 61px 83px;
    h4 {
      font-size: 24px;
      font-weight: 600;
      color: #242425;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #242425;
    }
    .green-button {
      background: #00db6e;
      color: white;
      font-size: 18px;
      border: none;
      box-shadow: unset;
    }
    .red-button {
      background: #fa5748;
      color: white;
      font-size: 18px;
      border: none;
      box-shadow: unset;
    }
  }
}
.product-addons-modal {
  .ant-modal-body {
    padding: 0 0 15px 0;
    table {
      th {
        background: #16273b;
        color: white;
        border-radius: 0 !important;
      }
    }
    .ant-btn {
      width: 200px;
      display: block;
      margin: 15px auto 0 auto;
    }
  }
}
.product-status-change-confirm-modal {
  .ant-modal-confirm-content {
    margin: auto;
  }
  .anticon {
    font-size: 50px;
  }
  .heading {
    font-size: 25px;
    font-weight: bold;
    margin: 10px 0;
  }
  .ant-modal-confirm-btns {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row-reverse;
    * {
      flex: 1;
      margin: 0 5px;
    }
    .ant-btn-primary {
      background: #37c627;
      border-color: #37c627;
    }
  }
}
