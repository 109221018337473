.forgot-password-form {
  height: 100%;
  a {
    color: #285be0;
  }
  .sidebar {
    height: 100%;
    background: url("../../../assets/images/backgrounds/login_illustration.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .sub-heading {
    font-size: 1.43rem;
    text-align: center;
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .form-button {
    display: inline-block;
    background: #285be0;
    color: #ffffff;
    font-size: 1rem;
    text-align: center;
    width: 50%;
    border: none;
    outline: none;
  }

  .anticon.anticon-left-circle {
    font-size: 2rem;
  }
}
