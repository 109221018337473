.translationModal {
  width: 650px !important;
  .ant-btn.ant-dropdown-trigger {
    height: 32px !important;
  }
  .form-item-group {
    margin-bottom: 16px;
  }
  p.form-labels {
    display: none;
  }
  label {
    white-space: normal;
    text-align: left;
    line-height: 1.5;
    margin-bottom: 4px;
  }
  .error-label {
    color: red;
    font-weight: medium;
  }
}
