.onboarding-footer {
  position: sticky;
  top: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10px;
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 11.786rem;

    a {
      color: rgba(0, 0, 0, 0.45) !important;
      text-decoration: none !important;
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .copyright-text {
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.857rem;
    font-weight: 400;
  }
}
