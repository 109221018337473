.main-heading-bar {
  padding: 3rem 0 2rem 0;
  color: #000;

  .title {
    font-size: 1.78rem;
    font-weight: 500;
    margin-bottom: 0;
  }
  .subtitle {
    font-size: 1.1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 0;
  }
  .page-cta {
    margin-top: 6px;
    width: 100%;
  }

  .actions {
    text-align: right;

    .ant-btn {
      background: #ff5858;
      color: #fff;
      outline: none !important;
      border-color: #ff5858 !important;
      border-radius: 2px;
      font-size: 1rem;
    }
  }
}
