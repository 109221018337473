.account-settings {
  padding: 1.5rem 3rem;
  p {
    margin: 0;
  }
  .color-picker-container {
    position: absolute;
    bottom: 100%;
    .chrome-picker {
      position: absolute;
      bottom: -100%;
    }
  }
  label {
    color: #151515;
    font-size: 1.14rem;
    margin-bottom: 0.5rem;
    font-weight: 400;
  }
  .upload-actions {
    margin-top: 0.71rem;
    display: flex;
    .upload-action {
      width: 7.14rem;
      height: 7.14rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      color: rgba(0, 0, 0, 0.25);
      font-size: 1rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      cursor: pointer;
      .anticon {
        font-size: 2.25rem;
      }
    }
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .text {
      font-size: 1.43rem;
      font-weight: 400;
      color: #151515;
    }

    .ant-btn {
      background: #36c626;
      border-radius: 4px;
      border-color: #36c626 !important;
      font-size: 1rem;
      color: #fff;
      font-weight: 500;
      padding: 0.5rem 1.5rem;
      min-height: 2.5rem;
    }
  }

  .ant-divider {
    background: #979797;
    height: 2px;
  }

  .input-container {
    margin-bottom: 1rem;

    .label {
      color: #151515;
      font-size: 1.14rem;
      margin-bottom: 0.5rem;
      font-weight: 400;
    }

    .ant-input {
      border: 1px solid #979797;
      border-radius: 4px;
      font-size: 1rem;
      padding: 1rem;
    }
    .ant-input-disabled {
      background: #f1f0f5;
    }
    .ant-select-selection:hover {
      border: 1px solid #979797;
    }

    .switch-text {
      font-size: 1rem;
      font-weight: 400;
      color: #8c8c8c;

      .ant-switch {
        vertical-align: baseline;
        margin-left: 0.5rem;
      }
      .ant-switch-checked {
        background: #36c626;
      }
    }
  }
  .col-1 {
    .input-container {
      margin-bottom: 2rem;
    }
  }

  .domain_name_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .ant-input {
      flex: 0.9;
    }

    .anticon {
      flex: 0.3;
      cursor: pointer;

      svg {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }

  .add-domain-btn {
    background: #ff5858;
    border-color: #ff5858;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 7px;
    height: 2.5rem;

    .anticon + span {
      margin-left: 0.25rem;
    }
  }
  .add-domain-btn:hover,
  .add-domain-btn:active,
  .add-domain-btn:focus,
  .add-domain-btn:focus-within {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  }
}
