.switch-account-modal {
  .switch-account-text {
    .title {
      color: #5d5c5c;
    }

    button {
      display: block;
      width: 100%;
      min-height: 3rem;
      text-align: left;
      /* padding: initial; */
      font-size: 1rem;
      border: 2px solid #979797;
      margin-bottom: 1rem;

      span {
        // width: 90%;
        .ant-tag {
          margin-left: 15px;
        }
      }

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
    button:hover {
      font-weight: 500;
      background: #e5ddff;
      color: #5d5c5c;
      svg {
        width: 1.3rem;
        height: 1.3rem;
      }
    }
  }
}
