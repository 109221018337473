.login-form {
  height: 100%;
  a {
    color: #285be0;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #285be0;
    border-color: #285be0;
  }
  .sidebar {
    height: 100%;
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center;
  }
  .form-button {
    display: block;
    background: #285be0;
    color: #ffffff;
    font-size: 1rem;
    text-align: center;
    width: 100%;
    border: none;
    outline: none;
  }
}
