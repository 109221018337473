.login-form-container {
  padding: 5rem 2rem 0 2rem;
  height: 100%;
  background: white;
  background-size: cover;
  background-repeat: no-repeat;

  .content-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    height: 100%;
  }

  .logo {
    margin: auto;
    width: 13rem;
    display: block;
  }

  .tagline {
    text-align: center;
    margin-top: 0.7rem;
    font-weight: 300;
  }

  .content {
    padding-top: 3rem;
    .heading {
      color: #072c4b;
      font-size: 32px;
      text-align: center;
      font-weight: 400;
      margin-bottom: 0;
    }
    .subheading {
      font-size: 16px;
      text-align: center;
    }

    .heading-separator {
      border-color: #072c4b;
      margin: auto;
      width: 45%;
      margin-bottom: 1.5rem;
    }

    .ant-form-item {
      width: 80%;
      margin: auto;
      margin-bottom: 1rem;
      font-size: 1rem;
    }

    .ant-input {
      border-color: #d9d9d9;
    }

    .flex-div {
      .ant-form-item-children {
        display: flex;
        font-size: 1rem;
        flex-wrap: wrap;
      }

      .gap {
        flex: 1;
      }
    }
  }

  .footer {
    position: sticky;
    top: 100%;
    padding-bottom: 30px;

    .links {
      display: flex;
      margin-left: 30%;
      width: 50%;

      a {
        flex: 1;
      }
    }

    .copyright-text {
      margin-left: 32%;
      width: 50%;
    }
  }
}
