.campaign-steps {
  .ant-modal-content {
    .ant-modal-close {
      top: -40px;
      right: -60px;
      .ant-modal-close-x {
        font-size: 28px;
        .anticon {
          color: white;
        }
      }
    }

    .ant-modal-body {
      height: auto;
      .main-steps-container {
        .steps-header {
          display: flex;
          justify-content: space-between;
          .step-info {
            width: 50%;
            h1 {
              .anticon {
                margin-right: 10px;
              }
              font-size: 30px;
              color: #242425;
              font-weight: 600;
              margin-bottom: 0px;
            }
            p {
              font-size: 18px;
              color: #242425;
              font-weight: 400;
              margin-bottom: 0px;
            }
          }
        }
        .step-name {
          margin-top: 30px;
          .ant-btn-link {
            font-size: 15px !important;
            font-weight: 600;
            color: #1654be;
            border: none;
            padding: 0px;
            box-shadow: unset;
          }
          p {
            font-size: 13px !important;
            color: #242425 !important;
            font-weight: 400 !important;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
