.categoryModal {
  .upload-actions {
    margin-top: 0.71rem;
    display: flex;
    .upload-action {
      width: 7.14rem;
      height: 7.14rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      color: rgba(0, 0, 0, 0.25);
      font-size: 1rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      cursor: pointer;
      .anticon {
        font-size: 2.25rem;
      }
    }
  }
  .ant-modal-header {
    padding: 1.5rem 1.5rem 0px;
    border-bottom: none;
    .ant-modal-title {
      margin: 0;
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      color: black;
    }
  }
  .form-item-group {
    margin-bottom: 15px;
    label {
      display: block;
      text-align: left;
    }
  }
  .form-item-group.has-error {
    label {
      color: red;
    }
    .error-label {
      color: red;
    }
    .ant-input {
      border-color: red;
    }
  }
  .ant-form-item-label {
    line-height: 27px;
    font-size: 14px;
    color: #242425;
    // margin-bottom: 8px;
  }
  .ant-modal-footer {
    padding: 0 1.5rem 1.5rem;
    border-top: none;
    text-align: center;
    .ant-btn {
      font-size: 0.75rem;
      font-weight: 500;
      width: 9rem;
      box-shadow: none;
    }
  }
}
