.product-card {
  .category-select {
    .ant-select-selection__choice {
      display: none;
    }
  }
  .upload-actions {
    margin-top: 0.71rem;
    display: flex;
    .upload-action {
      width: 7.14rem;
      height: 7.14rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      color: rgba(0, 0, 0, 0.25);
      font-size: 1rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      cursor: pointer;
      .anticon {
        font-size: 2.25rem;
      }
    }
  }
  h4 {
    font-size: 24px;
    color: #fa5748;
    margin-bottom: 8px;
  }
  .form-item-group {
    margin-bottom: 15px;
    label {
      display: block;
      text-align: left;
    }
  }
  .form-item-group.has-error {
    label {
      color: red;
    }
    .error-label {
      color: red;
    }
    .ant-input {
      border-color: red;
    }
  }
  .add-addon-link {
    display: block;
    text-align: center;
    margin: auto;
    color: #fa5748;
    font-weight: 500;
    font-size: 18px;
  }
  .addon {
    display: flex;
    * {
      flex: 1;
      padding: 0 5px;
    }
  }
  .ql-editor {
    min-height: 400px;
  }
  .ant-form-item-label {
    line-height: 27px;
    // label {
    font-size: 16px;
    color: black;
    margin-bottom: 8px;
    // }
  }
  .ant-input {
    background: #efefef;
    border: 1px solid transparent;
    border-radius: 2px;
  }
  .footer-button {
    width: 100%;
    background: #1bca00;
    color: white;
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    margin-top: 20px;
  }
  .ant-select-selection {
    background-color: #efefef;
    border-radius: 2px;
    border: none;
  }
  .main-product-container {
    .product-section {
      margin-top: 16px;
      font-size: 16px;
      color: black;
      margin-bottom: 8px !important;
      text-align: left;
    }
  }
}
